import "./index.css";
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import { useNavigate, } from 'react-router-dom';
import News from 'components/news/index';

import news10 from "assets/images/encoInfo/newsCenter/news10.png";
import news9 from "assets/images/encoInfo/newsCenter/news9.png";
import news8 from "assets/images/encoInfo/newsCenter/news8.png";
import news7 from "assets/images/encoInfo/newsCenter/news7.png";
import news6 from "assets/images/encoInfo/newsCenter/news6.png";
import news5 from "assets/images/encoInfo/newsCenter/news5.png";

export default () => {
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();

  const newsImg = [
    {
      img: news10,
      date: "Jan. 15, 2025",
      msg: "行业重磅 | 享刻智能牵头制定发布首个具身智能技术厨房烹饪场景团体标准",
      link: "https://mp.weixin.qq.com/s/FIjVNaaDv4oaEKG-MPT2GA",
    },
    {
      img: news9,
      date: "Dec. 24, 2024",
      msg: "喜报 | 享刻智能顺利通过“国家高新技术企业”资质认定",
      link: "https://mp.weixin.qq.com/s/CgE5M0anOC2BIKCGxycPCw",
    },
    {
      img: news8,
      date: "Oct. 11, 2024",
      msg: "央视！中国日报！享刻智能LAVA厨房机器人登上中国官方媒体头条",
      link: "https://mp.weixin.qq.com/s/14XGalru0p9QT3ndsrJkMg",
    },
  ];

  const handleLinkPastNews = () => {
    navigate("/encoInfo/newsCenter")
  }

  return (
    <div className="new_container">
      {contextHolder}
      <div className="content">
        <div className="title">{t("LatestNews")}</div>
        <div className="btn_wrapper">
          <button className="history_btn" onClick={handleLinkPastNews}> {t("PastNews")} {">>"} </button>
        </div>
        <News data={newsImg} />
      </div>
    </div>
  );
};