// import react from "react";
import investor1 from 'assets/images/investors/1.png'
import investor2 from 'assets/images/investors/2.png'
import investor3 from 'assets/images/investors/3.png'
import investor4 from 'assets/images/investors/4.png'
import investor5 from 'assets/images/investors/5.png'
import investor6 from 'assets/images/investors/6.png'
import investor7 from 'assets/images/investors/7.png'
import investor8 from 'assets/images/investors/8.png'
import investor9 from 'assets/images/investors/9.png'
import investor10 from 'assets/images/investors/10.png'

import "./index.css";
import { useTranslation } from 'react-i18next'

export default () => {
    const { t } = useTranslation();

  return (
    <div className="investors_container">
        <div className="title">{t("Investors")}</div>
        <div className="investor_name">
            <div className="img-item">
                <img src={investor1} alt="真格基金" />
            </div>
            <div className="img-item">
                <img src={investor2} alt="中关村科学家基金" />
            </div>
            <div className="img-item">
                <img src={investor3} alt="德迅投资" />
            </div>
            <div className="img-item">
                <img src={investor4} alt="中关村资本" />
            </div>
            <div className="img-item">
                <img src={investor5} alt="启迪之星" />
            </div>
            <div className="img-item">
                <img src={investor6} alt="九阳股份" /> 
            </div>
            <div className="img-item">
                <img src={investor7} alt="振邦智能" /> 
            </div>
            <div className="img-item">
                <img src={investor8} alt="美年健康" /> 
            </div>
            <div className="img-item">
                <img src={investor9} alt="中关村东升科技园" /> 
            </div>
            <div className="img-item">
                <img src={investor10} alt="东升蓟门资管" /> 
            </div>
        </div>
    </div>
  );
};